<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      title="Edit Material Request"
    >
      <b-form
          @submit.prevent="submitEditMaterialRequest"
>
        <b-row>
          <b-col cols="12">
            <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
            >
            <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
            />
            Back
            </b-button>
            <div class="devider" />
            <b-form-group
              label="To *"
              label-for="material_request_to"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_to"
                v-model="materialRequestTo"
                placeholder="To."
                required="required"
              />
            </b-form-group>
            <b-form-group
              label="From *"
              label-for="material_request_cc"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_cc"
                v-model="materialRequestCc"
                placeholder="From"
                required="required"
              />
            </b-form-group>
            <b-form-group
              label="Reason"
              label-for="material_request_reason"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_reason"
                v-model="materialRequestReason"
                placeholder="Reason"
              />
            </b-form-group>
            <b-row>
              <b-col
cols="8"
class="text-center font-weight-bold"
>
                Item name
              </b-col>
              <b-col
cols="1"
class="text-center font-weight-bold"
>
                Item Unit
              </b-col>
              <b-col
cols="1"
class="text-center font-weight-bold"
>
                Item QTY
              </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Actions
              </b-col>
              </b-row>
             <p />
            <b-row
v-for="(chosen) in chosens"
:key="chosen.value"
>
            <br>
              <b-col cols="8 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
{{ chosen.label }}
</b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="1 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                {{ chosen.item.unit? chosen.item.unit.unit_name : '-' }}
</b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="1 text-center">
              <b-form-input
                v-model="chosen.qty"
                type="number"
                placeholder="Qty"
                min="1"
                required="required"
                :max="chosen.qty"
              />
              </b-col>
              <b-col cols="2 text-center">
            <b-button
              size="sm"
              type="button"
              variant="danger"
              class="mr-1"
              @click="removeRow(chosen.value)"
            >
                  <feather-icon
                    icon="TrashIcon"
                    size="18"
                  />
            </b-button>
              </b-col>
              <b-col cols="12">
                <br>
              </b-col>
              </b-row>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

  const submitButtonDisabled = false
  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const types = [
      { label: "Head Office", value: "true" },
      { label: "Cabang", value: "false" },
    ]
  const chosens = []
  const materialRequestTo = ''
  const materialRequestCc = ''
  const materialRequestReason = ''
  const materialRequestQtyRequested = 0
  const materialRequestStatus = ''
  const materialRequestId = ''
  export default {
    components: {
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        types,
     materialRequestId,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtyRequested,
      materialRequestStatus,
      submitButtonDisabled,
      }
    },
    mounted() {
        this.getItems()
        this.getDetail()
    },
    methods: {
    getDetail() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = JSON.parse(localStorage.getItem('materialRequestDetail'))
      this.modalDetailShow = true
      this.materialRequestTo = item.material_request_to
      this.materialRequestCc = item.material_request_cc
      this.materialRequestReason = item.material_request_reason
      this.materialRequestId = item.material_request_id
      const id = item.material_request_id
      this.materialRequestId = id
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
        const itemsJoin = response.data.data.map(elem => (
            {
              id: elem.material_request_detail_id, label: elem.item.item_name, item: elem.item, value: elem.material_request_detail_item_id, qty: elem.material_request_detail_item_qty_requested,
            }
        ))
        this.chosens = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    getItems() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                const itemsJoin = response.data.data.map(elem => (
                  { label: elem.item_name, value: elem.item_id }
                ))
                this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Data Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Data Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Settings Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    submitEditMaterialRequest() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      this.submitButtonDisabled = true
      if (this.chosens.length > 0) {
      this.chosens.map(elem => {
        this.materialRequestQtyRequested += parseInt(elem.qty)
      })
        const body = {
          material_request_id: this.materialRequestId,
          material_request_to: this.materialRequestTo,
          material_request_cc: this.materialRequestCc,
          material_request_reason: this.materialRequestReason,
          material_request_qty_requested: this.materialRequestQtyRequested,
          material_request_items: JSON.stringify(this.chosens),
          is_edit: true,
          time: moment(),
        }
        console.log(body)
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_MATERIAL_REQUEST}`, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.chosens = []
            this.materialRequestQtyRequested = 0
            this.materialRequestReason = ""
            this.materialRequestCc = ""
            this.materialRequestTo = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Material Request Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-material-request-list' })
          } else {
            this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'You cannot delete all items, at least put one item!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      }
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: 1, item: elem.item,
                   }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: 1, item: elem.item,
                  }
                }
            })
            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
        previous() {
            this.$router.push({ name: 'apps-material-request-list' })
        },
    },
  }
  </script>
